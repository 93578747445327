.consulting-container {
    color: white;
}
.consulting-header {
    padding-top: 2%;
    font-weight: bolder;
    text-align: center;
    font-size: 30px;
}

.consulting-sub-header {
    padding-top: 3%;
    padding-bottom: 3%;
    text-align: center;
    font-weight: bold;
    font-size: 20px;
}

.consulting-sub-title {
    font-size: 1rem;
    text-align: center;
    font-weight: bold;
    padding-left: 2%;
    padding-right: 2%;
}

.consulting-text {
      padding-top: 5%;
      display: flex;
      flex-direction: row;
      text-align: center;
      padding-bottom: 2%;
}

.consulting-sub-text-title {
    font-weight: bold;
    padding-bottom: 2%;
    font-size: 20px;
    text-decoration: underline;
    text-underline-offset: 10px;

}

.consulting-row  {
    padding-top: 5%;
    padding-bottom: 5%;
    padding-left: 5%;
    white-space: nowrap;
    font-family: Arial;
    text-decoration: underline;
    text-underline-offset: 15px;
}

.consulting-row-right  {
    padding-top: 5%;
    padding-bottom: 5%;
    padding-left: 5%;
    white-space: nowrap;
    font-family: Arial;
    text-decoration: underline;
    text-underline-offset: 15px;
}

.consulting-aws{
    flex-direction: row;
    padding-top: 2%;
    text-align: left;
    display: flex;
    padding-left: 18%;
    color: white;
    font-size: 15px;
}

.consulting-aws-details{
    flex-direction: column;
    text-align: left;
    display: flex;
    /*border: 1px solid;*/
    border-collapse:collapse;
    font-size: 15px;
    width: 25%;
    /*background-color: #b1b1b1;*/
}
.consulting-aws-details-right{
    flex-direction: column;
    text-align: left;
    display: flex;
    /*border: 1px solid;*/
    border-collapse:collapse;
    font-size: 15px;
    padding-left: 25%;

    /*background-color: #b1b1b1;*/
}
@media screen and (max-width: 450px) {

    .consulting-header {
        padding-top: 13%;
        font-weight: bolder;
        text-align: center;
        font-size: 30px;
    }

    .consulting-sub-header {
        padding-top: 6%;
        padding-bottom: 6%;
        text-align: center;
        font-weight: bold;
        font-size: 20px;

    }

    .consulting-aws-details{
        flex-direction: column;
        text-align: left;
        display: flex;
        /*border: 1px solid;*/
        border-collapse:collapse;
        font-size: 15px;
        width: 25%;
        /*background-color: #b1b1b1;*/
    }

    .consulting-aws-details-right {
        flex-direction: column;
        text-align: left;
        display: flex;
        /*border: 1px solid;*/
        border-collapse:collapse;
        font-size: 15px;
        padding-left: 20%;
        /*background-color: #b1b1b1;*/
    }

    .consulting-aws{
        flex-direction: row;
        padding-top: 5%;
        text-align: left;
        display: flex;
        padding-left: 2%;
        color: white;
        font-size: 15px;
    }

    .consulting-row  {
        padding-top: 5%;
        padding-bottom: 15%;
        padding-left: 5%;
        white-space: nowrap;
        font-family: Arial;
        text-decoration: underline;
        text-underline-offset: 9px;
        font-size: 11px;
    }

    .consulting-row-right  {
        padding-top: 5%;
        padding-bottom: 5%;
        padding-left: 5%;
        white-space: nowrap;
        font-family: Arial;
        text-decoration: underline;
        text-underline-offset: 12px;
        font-size: 11px;
    }

}

@media screen and (max-height: 375px) {
    .consulting-container{
        color: white;
        overflow-y: auto;
        height: 295px;
    }

}

@media screen and (max-height: 400px) {
    .consulting-container{
        color: white;
        overflow-y: auto;
        height: 295px;
    }
}