.careers-main{
    display: flex;
    flex-direction: row;
}
.careers-container{
    color: white;
    width: 200%;
}
.careers-logo-container{
    padding-top: 10%;
}
.careers-header {
    font-weight: bolder;
    padding-left: 1%;
    padding-top: 10%;
    font-size: 30px;
    color: white;
}
.careers-text {
    padding-top: 2%;
    padding-left: 2%;
    color: white;
}

@media screen and (max-width: 450px) {

    .careers-header {
        font-weight: bolder;
        padding-left: 1%;
        padding-top: 10%;
        font-size: 20px;
        color: white;
    }

    .careers-text {
        padding-top: 2%;
        padding-left: 2%;
        padding-right: 1%;
        color: white;
        font-size: 15px;
    }

}
@media screen and (max-width: 375px) {

    .careers-header {
        font-weight: bolder;
        padding-left: 1%;
        padding-top: 10%;
        font-size: 20px;
        color: white;
    }

    .careers-text {
        padding-top: 2%;
        padding-left: 2%;
        padding-right: 2%;
        color: white;
        font-size: 13px;
    }

    .careers-container{
        color: white;
        width: 210%;
    }

}

@media screen and (max-height: 375px) {
    .careers-container{
        color: white;
        width: 200%;
        overflow-y: auto;
        height: 295px;
    }

}

@media screen and (max-height: 400px) {
    .careers-container{
        color: white;
        width: 200%;
        overflow-y: auto;
        height: 295px;
    }

}