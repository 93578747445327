.opt-header {
    text-align: center;
    font-weight: bold;
    font-size: 30px;
    padding-top: 5%;
    padding-bottom: 3%;
    color: white;
}

.opt-container {

}

.opt-text {
    padding-top: 2%;
    text-align: center;
    color: white;
    padding-left: 8%;
    font-size: 16px;
    padding-right: 8%;
    font-weight: bold;
}

.opt-aws{
    flex-direction: row;
    padding-top: 3%;
    text-align: left;
    display: flex;
    padding-left: 20%;
    color: white;
    font-size: 15px;
}

.opt-aws-details{
    flex-direction: column;
    text-align: left;
    display: flex;
    /*border: 1px solid;*/
    border-collapse:collapse;
    font-size: 15px;
    width: 25%;
    white-space: nowrap;
    /*background-color: #b1b1b1;*/
}
.opt-row  {
    padding-top: 5%;
    padding-bottom: 5%;
    padding-left: 5%;
    border-bottom: 1px solid white;
    white-space: nowrap;
    font-family: Arial;
}

.opt-aws-details-right {
    flex-direction: column;
    text-align: left;
    display: flex;
    /*border: 1px solid;*/
    border-collapse:collapse;
    font-size: 15px;
    /*width: 25%;*/
    white-space: nowrap;
    padding-left: 20%;
}
.opt-aws-item {
    padding-bottom: 5%;
    padding-top: 5%;
    text-decoration: underline;
    text-underline-offset: 15px;
}
.opt-aws-item-right {
    padding-bottom: 6%;
    padding-top: 5%;
    text-decoration: underline;
    text-underline-offset: 15px;
}

@media screen and (max-width: 450px) {

    .opt-aws-details{
        flex-direction: column;
        text-align: left;
        display: flex;
        /*border: 1px solid;*/
        border-collapse:collapse;
        font-size: 12px;
        width: 25%;
        /*white-space: nowrap;*/
        padding-left: 0.2%;
        /*background-color: #b1b1b1;*/
    }

    .opt-aws{
        flex-direction: row;
        padding-top: 18%;
        text-align: left;
        display: flex;
        padding-left: 8%;
        color: white;
        font-size: 10px;
    }

    .opt-aws-details-right {
        flex-direction: column;
        text-align: left;
        display: flex;
        /*border: 1px solid;*/
        border-collapse:collapse;
        font-size: 12px;
        /*white-space: nowrap;*/
        padding-left: 20%;
    }
    .opt-aws-item {
        padding-bottom: 10%;
        padding-top: 5%;
        text-decoration: underline;
        text-underline-offset: 15px;
    }
    .opt-aws-item-right {
        padding-bottom: 5%;
        padding-top: 2%;
        box-sizing: unset;
        text-decoration: underline;
        text-underline-offset: 15px;
    }

}

@media screen and (max-width: 375px) {
    .opt-header {
        text-align: center;
        font-weight: bold;
        font-size: 28px;
        padding-top: 5%;
        padding-bottom: 3%;
        color: white;
    }

    .opt-aws-details-right {
        flex-direction: column;
        text-align: left;
        display: flex;
        /*border: 1px solid;*/
        border-collapse:collapse;
        font-size: 12px;
        /*white-space: nowrap;*/
        padding-left: 18%;
    }
}

@media screen and (max-height: 375px) {
    .opt-container{
        color: white;
        overflow-y: auto;
        height: 295px;
    }

}

@media screen and (max-height: 400px) {
    .opt-container{
        color: white;
        overflow-y: auto;
        height: 295px;
    }

}