video {
    /*object-fit: cover;*/
    width: inherit;
    height: 100vh;
    position: fixed;
    top: 0;


    /*position: relative;*/
    /*left: 0;*/
    /*top: 0;*/
    /*width: 100vw;*/
    /*height: 100vh;*/
    /*z-index: -1;*/

    /*object-fit: cover;*/
    /*width: 100vw;*/
    /*height: 100vh;*/
    /*position: fixed;*/
    /*top: 0;*/
    /*left: 0;*/
    /*transform:scale(0.9);*/

    /*position: fixed;*/
    /*z-index: -1;*/
}
.hero-bar{
    background: black;
    height: 80px;
    position: relative;
    margin-top: 500px;
}

.hero-section {
    display: flex;
    flex-direction: column;
}
.hero-text {
    background-image: url("bg.png");
    color: white;
    background-repeat: no-repeat;
    margin-top: 0%;
}
/*video.scale2{*/
    /*-moz-transform:scale(0.5);*/
    /*-webkit-transform:scale(0.5);*/
    /*-o-transform:scale(0.5);*/
    /*-ms-transform:scale(0.5);*/
    /*transform:scale(0.5);*/
/*}*/
.hero-video {
    width: 100%;
    height: 100%;
}

.hero-container {
    /* background: url('/images/img-home.jpg') center center/cover no-repeat; */
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /*box-shadow: inset 0 0 0 1000px #b1b1b1;*/
    object-fit: contain;
    background-size: contain;
}

.hero-container > h1 {
    color: #fff;
    font-size: 100px;
    margin-top: -100px;
}

.hero-container > p {
    margin-top: 8px;
    color: #fff;
    font-size: 32px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.hero-search {
    /*background-color: #b1b1b1;*/
    /*background-image: url("bg.png");*/
    /*background-repeat: no-repeat;*/
    background-color: black;
    /*background-size: cover;*/
    color: white;
    width: inherit;
    padding-top: 10%;
}
.hero-search-text {
    align-items: center;
    justify-content: center;
    text-align: center;
    color: white;
    padding-top: 2%;
    padding-bottom: 2%;
    text-underline: white;
}

.hero-btns {
    background-color: pink;
}

.hero-btns .btn {
    margin: 6px;
}

.fa-play-circle {
    margin-left: 4px;
}
.hero-search-text-box-container {
    padding-bottom: 2%;
    padding-top: 2%;
    margin-left: 2%;
}
.hero-search-text-box {
    margin-left: 10%;
}

@media screen and (max-width: 1100px) {
    video {
        /*object-fit: cover;*/
        /*width: inherit;*/
        /*height: inherit;*/
        /*position: relative;*/
        /*left: 50px;*/

        position: relative;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        /*z-index: -1;*/
    }
    .hero-bar{
        background: black;
        /*background-image: url("bg.png");*/
        /*background-repeat: no-repeat;*/
        height: 80px;
        position: relative;
        margin-top: 500px;
    }

    .hero-video {
        width: 100%;
        /*height: 68%;*/
    }

    .hero-section {
        display: flex;
        flex-direction: column;
    }
    .hero-text {
        background-image: url("bg.png");
        color: white;
        background-repeat: no-repeat;
        margin-top: 0%;
    }
    .hero-container {
        /* background: url('/images/img-home.jpg') center center/cover no-repeat; */
        background-color: black;
        /*background-image: url("bg.png");*/
        /*background-repeat: no-repeat;*/
        /*background-size: cover;*/
        height: 100vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        /*box-shadow: inset 0 0 0 1000px #b1b1b1;*/
        object-fit: contain;
    }

}

@media screen and (max-width: 450px) {
    video {
        /*object-fit: cover;*/
        /*width: inherit;*/
        /*height: inherit;*/
        /*position: relative;*/
        /*left: 50px;*/

        position: absolute;
        left: 0;
        top: -20%;
        width: 100vw;
        height: 100vh;
        /*z-index: -1;*/
    }
    .hero-bar{
        background: black;
        /*background-image: url("bg.png");*/
        /*background-repeat: no-repeat;*/
        height: 100px;
        position: relative;
        margin-top: 500px;
    }

    .hero-video {
        width: 100%;
        /*height: 68%;*/
    }

    .hero-section {
        display: flex;
        flex-direction: column;
    }
    .hero-text {
        background-image: url("bg.png");
        color: white;
        background-repeat: no-repeat;
        margin-top: 0%;
    }
    .hero-container {
        /* background: url('/images/img-home.jpg') center center/cover no-repeat; */
        background-color: black;
        /*background-image: url("bg.png");*/
        /*background-repeat: no-repeat;*/
        /*background-size: cover;*/
        height: 100vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        /*box-shadow: inset 0 0 0 1000px #b1b1b1;*/
        object-fit: contain;
    }

}


@media screen and (max-height: 369px) {
    .hero-container {
        /* background: url('/images/img-home.jpg') center center/cover no-repeat; */
        background-color: black;
        /*background-image: url("bg.png");*/
        /*background-repeat: no-repeat;*/
        /*background-size: cover;*/
        height: 100vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        /*box-shadow: inset 0 0 0 1000px #b1b1b1;*/
        object-fit: contain;
    }

    video {
        /*object-fit: cover;*/
        width: inherit;
        height: 100vh;
        position: fixed;
        top: 0;
    }

}

@media screen and (max-height: 400px) {
    .hero-container {
        /* background: url('/images/img-home.jpg') center center/cover no-repeat; */
        background-color: black;
        /*background-image: url("bg.png");*/
        /*background-repeat: no-repeat;*/
        /*background-size: cover;*/
        height: 100vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        /*box-shadow: inset 0 0 0 1000px #b1b1b1;*/
        object-fit: contain;
    }

    video {
        /*object-fit: cover;*/
        width: inherit;
        height: 100vh;
        position: fixed;
        top: 0;
    }

}

/*@media screen and (max-width: 960px) {*/
    /*.hero-container > h1 {*/
        /*font-size: 70px;*/
        /*margin-top: -150px;*/
    /*}*/
/*}*/

/*@media screen and (max-width: 768px) {*/
    /*.hero-container > h1 {*/
        /*font-size: 50px;*/
        /*margin-top: -100px;*/
    /*}*/

    /*.hero-container > p {*/
        /*font-size: 30px;*/
    /*}*/

    /*.btn-mobile {*/
        /*display: block;*/
        /*text-decoration: none;*/
    /*}*/

    /*.btn {*/
        /*width: 100%;*/
    /*}*/
/*}*/