.ms-container {
    color: white;
}
.ms-header {
    padding-top: 2%;
    padding-left: 2%;
    color: white;
    display: flex;
    flex-direction: row;
}
.ms-table{
    /*border: 1px solid;*/
    border-collapse:collapse;
}
.ms-details {
    flex-direction: column;
    text-align: left;
    display: flex;
    /*border: 1px solid;*/
    border-collapse:collapse;
    font-size: 15px;
    width: 25%;
}
.ms-details-right {
    flex-direction: column;
    text-align: left;
    display: flex;
    /*border: 1px solid;*/
    border-collapse:collapse;
    font-size: 15px;
    padding-left: 25%;
}
.ms-row {
    padding-top: 5%;
    padding-bottom: 4%;
    padding-left: 5%;
    white-space: nowrap;
    font-family: Arial;
    text-decoration: underline;
    text-underline-offset: 15px;
}
.ms-row-right {
    padding-top: 4%;
    padding-bottom: 3%;
    padding-left: 5%;
    white-space: nowrap;
    font-family: Arial;
    text-decoration: underline;
    text-underline-offset: 15px;
}
.ms-main-header{
    text-align: center;
    font-weight: bold;
    font-size: 30px;
    color: white;
    padding-top: 5%;
    padding-bottom: 3%;
}
.ms-aws{
    flex-direction: row;
    padding-top: 2%;
    text-align: left;
    display: flex;
    padding-left: 18%;
    color: white;
    font-size: 15px;
}
.ms-text {
    color: white;
    text-align: center;
}

@media screen and (max-width: 450px) {

    .ms-main-header{
        text-align: center;
        font-weight: bold;
        font-size: 30px;
        color: white;
        padding-top: 15%;
        padding-bottom: 3%;
    }

    .ms-aws{
        flex-direction: row;
        padding-top: 15%;
        text-align: left;
        display: flex;
        padding-left: 3%;
        color: white;
        font-size: 15px;
    }

    .ms-details {
        flex-direction: column;
        text-align: left;
        display: flex;
        /*border: 1px solid;*/
        /*border-collapse:collapse;*/
        font-size: 15px;
        width: 25%;
    }
    .ms-details-right {
        flex-direction: column;
        text-align: left;
        display: flex;
        /*border: 1px solid;*/
        border-collapse:collapse;
        font-size: 15px;
        padding-left: 14%;
    }

    .ms-row {
        padding-top: 5%;
        padding-bottom: 4%;
        padding-left: 5%;
        white-space: nowrap;
        font-family: Arial;
        text-decoration: underline;
        text-underline-offset: 9px;
        font-size: 10px;
    }
    .ms-row-right {
        padding-top: 4%;
        padding-bottom: 3%;
        padding-left: 5%;
        font-family: Arial;
        text-decoration: underline;
        text-underline-offset: 10px;
        font-size: 10px;
    }
    .ms-text {
        color: white;
        text-align: center;
        padding-top: 4%;
    }

}

@media screen and (max-width: 375px) {
    .ms-row-right {
        padding-top: 3%;
        padding-bottom: 3%;
        padding-left: 2%;
        font-family: Arial;
        text-decoration: underline;
        text-underline-offset: 10px;
        font-size: 10px;
    }

}

@media screen and (max-height: 375px) {
    .ms-container{
        color: white;
        overflow-y: auto;
        height: 295px;
    }

}

@media screen and (max-height: 400px) {
    .ms-container{
        color: white;
        overflow-y: auto;
        height: 295px;
    }

}
/*.ms-row  {*/
    /*padding-top: 8%;*/
    /*padding-bottom: 8%;*/
    /*padding-left: 5%;*/
    /*border-bottom: 1px solid white;*/
/*}*/