.training-main{
    display: flex;
    flex-direction: row;
    /*background-image: url("trainingBg.jpg");*/
    /*background-size: cover;*/
    /*background-repeat: no-repeat;*/
}
.training-container {
    color: white;
    width: 200%;
    /*background-image: url("trainingBg.jpg");*/
}
.training-header{
    font-weight: bolder;
    padding-left: 3%;
    padding-top: 3%;
    font-size: 30px;
}

.training-content-container {
    padding-left: 3%;
    padding-top: 3%;
}

.training-content-header {
    font-weight: bolder;
}

.training-details {
    padding-top: 3%;
}
.training-duration {
    padding-top: 1%;
}
.training-instruction{
    /*padding-left: 2%;*/
    padding-top: 1%;
}
.training-logo-container{
    padding-top: 10%;
}

@media screen and (max-width: 375px) {
    .training-instruction{
        /*padding-left: 2%;*/
        padding-top: 1%;
        font-size: 11px;
    }
    .training-duration {
        padding-top: 1%;
        font-size: 11px;
    }
}

@media screen and (max-height: 375px) {
    .training-container{
        color: white;
        width: 200%;
        overflow-y: auto;
        height: 295px;
    }

}

@media screen and (max-height: 400px) {
    .training-container{
        color: white;
        width: 200%;
        overflow-y: auto;
        height: 295px;
    }

}