.aboutus-main{
    display: flex;
    flex-direction: row;
}
.aboutus-container {
    color: white;
    width: 200%;
}
.aboutus-header {
    font-weight: bolder;
    padding-left: 2%;
    padding-top: 3%;
    font-size: 30px;
    color: white;
}
.aboutus-text {
    padding-top: 2%;
    padding-left: 2%;
    padding-right: 1%;
}

.aboutus-sub-content {
    padding-top: 1%;
    padding-left: 1%;
}
.aboutus-logo-container {
    padding-top: 10%;
}

@media screen and (max-width: 450px) {
    .aboutus-header {
        font-weight: bolder;
        padding-left: 2%;
        padding-top: 7%;
        font-size: 20px;
        color: white;
    }

    .aboutus-text {
        padding-top: 2%;
        padding-left: 2%;
        padding-right: 1%;
        font-size: 10px;
    }

    .aboutus-header {
        font-weight: bolder;
        padding-left: 2%;
        padding-top: 7%;
        font-size: 20px;
        color: white;
    }
}

@media screen and (max-width: 380px) {
    .aboutus-header {
        font-weight: bolder;
        padding-left: 2%;
        padding-top: 7%;
        font-size: 20px;
        color: white;
    }

    .aboutus-text {
        padding-top: 2%;
        padding-left: 2%;
        padding-right: 1%;
        font-size: 6px;
    }

    .aboutus-header {
        font-weight: bolder;
        padding-left: 2%;
        padding-top: 7%;
        font-size: 20px;
        color: white;
    }
}

@media screen and (max-width: 800px) {
    .aboutus-header {
        font-weight: bolder;
        padding-left: 2%;
        padding-top: 7%;
        font-size: 20px;
        color: white;
    }

    .aboutus-text {
        padding-top: 2%;
        padding-left: 2%;
        padding-right: 1%;
        font-size: 11px;
    }

    .aboutus-header {
        font-weight: bolder;
        padding-left: 2%;
        padding-top: 7%;
        font-size: 20px;
        color: white;
    }
}

@media screen and (max-width: 650px) {
    .aboutus-header {
        font-weight: bolder;
        padding-left: 2%;
        padding-top: 7%;
        font-size: 20px;
        color: white;
    }

    .aboutus-text {
        padding-top: 2%;
        padding-left: 2%;
        padding-right: 1%;
        font-size: 10px;
    }

    .aboutus-header {
        font-weight: bolder;
        padding-left: 2%;
        padding-top: 7%;
        font-size: 20px;
        color: white;
    }
}

@media screen and (max-width: 375px) {
    .aboutus-header {
        font-weight: bolder;
        padding-left: 2%;
        padding-top: 7%;
        font-size: 20px;
        color: white;
    }

    .aboutus-text {
        padding-top: 2%;
        padding-left: 2%;
        padding-right: 1%;
        font-size: 10px;
    }

    .aboutus-header {
        font-weight: bolder;
        padding-left: 2%;
        padding-top: 7%;
        font-size: 20px;
        color: white;
    }
}

@media screen and (max-height: 375px) {
    .aboutus-container{
        color: white;
        width: 200%;
        overflow-y: auto;
        height: 295px;
    }

}