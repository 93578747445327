.contact-main {
    display: flex;
    flex-direction: row;
}
.contact-logo-container {
    padding-top: 10%;
}
.contact-header {
    font-weight: bolder;
    padding-left: 2%;
    padding-top: 18%;
    font-size: 30px;
    color: white;
}
.contact-container{
    color: white;
    width: 200%;
}
.contact-text{
    padding-top: 2%;
    padding-left: 2%;
}

.address-text{
    padding-top: 2%;
    padding-left: 2%;
}

@media screen and (max-width: 450px) {
    .contact-header {
        font-weight: bolder;
        padding-left: 2%;
        padding-top: 18%;
        font-size: 20px;
        color: white;
    }

    .contact-text{
        padding-top: 10%;
        padding-left: 2%;
        font-size: 15px;
    }
    .address-text{
        padding-top: 25%;
        padding-left: 2%;
    }

}
@media screen and (max-width: 375px) {
    .contact-header {
        font-weight: bolder;
        padding-left: 2%;
        padding-top: 18%;
        font-size: 20px;
        color: white;
    }

    .contact-text{
        padding-top: 10%;
        padding-left: 2%;
        font-size: 13px;
    }
    .address-text{
        padding-top: 25%;
        padding-left: 2%;
    }

}

@media screen and (max-height: 375px) {
    .contact-container{
        color: white;
        width: 200%;
        overflow-y: auto;
        height: 295px;
    }

}

@media screen and (max-height: 400px) {
    .contact-container{
        color: white;
        width: 200%;
        overflow-y: auto;
        height: 295px;
    }

}