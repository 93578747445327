.navbar {
    /*background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);*/
    background: black;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 999;
}
.navbar-logo-up {
    paddingBottom: '10%';
    display: inline-block;
}

.navbar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    max-width: 1500px;
}

.navbar-logo {
    color: #fff;
    justify-self: start;
    /*margin-left: 20px;*/
    margin-right: 30px;
    cursor: pointer;
    text-decoration: none;
    font-size: 15px;
    display: flex;
    align-items: center;
    padding: 0.5rem 0rem;
}

.fa-typo3 {
    /*margin-left: 0.5rem;*/
    font-size: 1.8rem;
}

.nav-menu {
    display: grid;
    grid-template-columns: repeat(8, auto);
    grid-gap: 0px;
    list-style: none;
    text-align: center;
    width: 60vw;
    justify-content: end;
    margin-right: 2rem;
}

.nav-menu-active {
    background: #242222;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
}

.nav-item {
    height: 55px;
}

.nav-links {
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    /*padding: 0.5rem 1rem;*/
    /*padding-top: 0.5rem;*/
    /*padding-bottom: 0.5rem;*/
    padding-left: 20px;
    /*padding-right: 1rem;*/
    height: 100%;
    font-size: 15px;
    white-space: nowrap;
}

.nav-links:hover {
    border-bottom: 4px solid #fff;
    transition: all 0.2s ease-out;
}

.fa-bars {
    color: #fff;
}

.nav-links-mobile {
    display: none;
}

.menu-icon {
    display: none;
}

/*ul.nav-menu {*/
    /*position: absolute;*/
    /*display: flex;*/
    /*flex-direction: row;*/
    /*width: 100%*/
/*}*/

/*li.nav-item {*/
    /*flex: 1 0 auto;*/
    /*padding: 10px;*/
    /*margin: 2px;*/
    /*background-color: grey;*/
    /*text-align: center;*/
/*}*/
img.fa-bars{
    display: none;
    padding: 10px;
    margin: 2px;
    background-color: grey
}

@media screen and (max-width: 1100px) {
    ul.nav-menu {
        flex-direction: column;
        display: none
    }
    ul.no-bullets {
        list-style-type: none; /* Remove bullets */
        padding: 0; /* Remove padding */
        margin: 0; /* Remove margins */
    }
    li.nav-item {
        background-color: black
    }
    img.fa-bars{
        display: block;
        position: absolute;
        padding: 10px;
        /*margin: 2px;*/
        margin-top: 19px;
        margin-left: -43px;
        background-color: grey
    }

    /*Commented*/
    /*.NavbarItems {*/
        /*position: relative;*/
    /*}*/

    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 90vh;
        position: absolute;
        top: 80px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
    }

    .nav-menu-active {
        background: black;
        /*left: 208%;*/
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
        position: absolute;
        top: 100%;
        width: 100%;
        list-style-type: none;
        padding-right: 20px;
    }

    .nav-links {
        text-align: center;
        /*padding: 2rem;*/
        width: 100%;
        display: table;
    }

    /*.nav-links:hover {*/
        /*background-color: #fff;*/
        /*color: #242424;*/
        /*!*border-radius: 0;*!*/
    /*}*/

    .navbar-logo {
        position: absolute;
        top: 0;
        left: 0;
        width: 40%;
        /*transform: translate(25%, 50%);*/
    }

    .menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
    }

    .fa-times {
        color: #fff;
        font-size: 2rem;
    }

    .nav-links-mobile {
        display: block;
        text-align: center;
        margin: 2rem auto;
        border-radius: 4px;
        width: 80%;
        text-decoration: none;
        font-size: 1.5rem;
        background-color: transparent;
        color: #fff;
        padding: 14px 20px;
        border: 1px solid #fff;
        transition: all 0.3s ease-out;
    }

    .nav-links-mobile:hover {
        background: #fff;
        color: #242424;
        transition: 250ms;
    }
}

@media screen and (max-width: 450px) {
    ul.nav-menu {
        flex-direction: column;
        display: none
    }
    ul.no-bullets {
        list-style-type: none; /* Remove bullets */
        padding: 0; /* Remove padding */
        margin: 0; /* Remove margins */
    }
    li.nav-item {
        background-color: black
    }
    img.fa-bars{
        display: block;
        position: absolute;
        padding: 10px;
        /*margin: 2px;*/
        margin-top: 8px;
        margin-left: -43px;
        background-color: grey
    }

    /*Commented*/
    /*.NavbarItems {*/
        /*position: relative;*/
    /*}*/

    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 90vh;
        position: absolute;
        top: 80px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
    }

    .nav-menu-active {
        background: black;
        /*left: 208%;*/
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
        position: absolute;
        top: 120%;
        list-style-type: none;
        padding-right: 20px;
        width: 100%;
    }

    .nav-links {
        text-align: center;
        /*padding: 2rem;*/
        width: 100%;
        display: table;
    }

    /*.nav-links:hover {*/
        /*background-color: #fff;*/
        /*color: #242424;*/
        /*!*border-radius: 0;*!*/
    /*}*/

    .navbar-logo {
        position: absolute;
        top: 0;
        left: 0;
        width: 50%;
        font-size: 11px;
        /*transform: translate(25%, 50%);*/
    }

    .menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
    }

    .fa-times {
        color: #fff;
        font-size: 2rem;
    }

    .nav-links-mobile {
        display: block;
        text-align: center;
        margin: 2rem auto;
        border-radius: 4px;
        width: 80%;
        text-decoration: none;
        font-size: 1.5rem;
        background-color: transparent;
        color: #fff;
        padding: 14px 20px;
        border: 1px solid #fff;
        transition: all 0.3s ease-out;
    }

    .nav-links-mobile:hover {
        background: #fff;
        color: #242424;
        transition: 250ms;
    }
}
@media screen and (max-width: 375px) {
    ul.nav-menu {
        flex-direction: column;
        display: none
    }
    ul.no-bullets {
        list-style-type: none; /* Remove bullets */
        padding: 0; /* Remove padding */
        margin: 0; /* Remove margins */
    }
    li.nav-item {
        background-color: black
    }
    img.fa-bars{
        display: block;
        position: absolute;
        padding: 10px;
        /*margin: 2px;*/
        margin-top: 8px;
        margin-left: -43px;
        background-color: grey
    }
    .navbar-logo {
        position: absolute;
        top: 0;
        left: 0;
        width: 50%;
        font-size: 11px;
        /*transform: translate(25%, 50%);*/
    }
    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 90vh;
        position: absolute;
        top: 80px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
    }

    .nav-menu-active {
        background: black;
        /*left: 208%;*/
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
        position: absolute;
        top: 120%;
        list-style-type: none;
        padding-right: 20px;
        width: 100%;
    }

    .nav-links {
        text-align: center;
        /*padding: 2rem;*/
        width: 100%;
        display: table;
    }
    .menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
    }

    .fa-times {
        color: #fff;
        font-size: 2rem;
    }

    .nav-links-mobile {
        display: block;
        text-align: center;
        margin: 2rem auto;
        border-radius: 4px;
        width: 80%;
        text-decoration: none;
        font-size: 1.5rem;
        background-color: transparent;
        color: #fff;
        padding: 14px 20px;
        border: 1px solid #fff;
        transition: all 0.3s ease-out;
    }

    .nav-links-mobile:hover {
        background: #fff;
        color: #242424;
        transition: 250ms;
    }

}